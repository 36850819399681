import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/questy-w-lbq.jpg";

export const query = graphql`
  {
    zdjecie1: file(
      relativePath: {
        eq: "analiza-potrzeb-klienta-zakres-oczekiwan-e1519822345780.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 785, layout: CONSTRAINED)
      }
    }
    zdjecie2: file(
      relativePath: { eq: "Szczegoly-oferty-handlowej-1440x1080.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 785, layout: CONSTRAINED)
      }
    }
  }
`;
const Lawbusinessquality = ({ data }) => {
  return (
    <Artykul
      title="Questy w Law Business Quality"
      keywords={["questy w law business quality"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Questy w “Law Business Quality”"
      metaTitle="Questy w “Law Business Quality”"
      metaDescription="Questy w “Law Business Quality” ✅ CEO firmy Questy o roli systemów IT • Dostawcy nowoczesnych rozwiązań dla biznesu"
    >
      <br />
      <p>
        W marcowym wydaniu „Law Business Quality” (nr 7/2016) ukazał się{" "}
        <strong> wywiad z CEO firmy Questy - Łukaszem Tadyszakiem</strong>,
        poświęcony roli systemów IT w stymulowaniu wzrostu w przedsiębiorstwach.
        Zapraszamy do lektury!
      </p>
      <br />
      <br />
      <h2>
        {" "}
        <strong>
          {" "}
          Dynamiczny wzrost firmy przy współudziale IT - złote zasady{" "}
        </strong>{" "}
      </h2>
      <p>
        Głównym celem każdej organizacji jest wzrost. Dziś o skali wzrostu w
        większym niż kiedykolwiek wcześniej stopniu decydują{" "}
        <strong> efektywnie wykorzystywane technologie IT</strong>. Co więcej,
        właściwe użycie systemów IT nierzadko decyduje o przetrwaniu firmy na
        rynku. Jakie tym samym powinny być{" "}
        <strong> złote zasady wykorzystania technologii informacyjnych</strong>{" "}
        przy podwyższaniu wzrostu firmy? Czym się kierować przy podejmowaniu
        decyzji o wdrożeniu nowych technologii, by najefektywniej je
        wykorzystać, a jednocześnie uniknąć wysokich kosztów i napięć w
        przedsiębiorstwie?
      </p>
       <br />
      <br />
      <h2>
        <strong>
          {" "}
          Cel oraz wizja, czyli co chcesz osiągnąć i po co chcesz to zrobić?{" "}
        </strong>{" "}
      </h2>
      <p>
        To oczywiste, że jeśli myślisz o zakupie nowej maszyny, wiesz dokładnie,
        z jakiego powodu jesteś gotów wydać na nią sporą kwotę. Najczęściej
        chodzi o{" "}
        <strong>
          {" "}
          możliwość wytworzenia czegoś nowego lub zwiększenie mocy produkcyjnych
        </strong>{" "}
        w celu zaspokojenia popytu na Twój produkt. Tymczasem jeśli pojawi się
        pomysł wdrożenia nowego system informatycznego, najczęściej objawia się
        tylko ogólna wizja (np. „chcemy mieć lepiej”), a szczegóły tej wizji są
        odzwierciedleniem standardowych funkcji wybranego systemu.
      </p>
      <p>
        Każdy doświadczony Project Manager odpowie, że jeśli musiałby określić
        jeden{" "}
        <strong>
          {" "}
          najistotniejszy element projektu informatycznego, będzie nim CEL
        </strong>{" "}
        – czyli dokładnie to, po co chcemy system wdrożyć. Realizacja złych
        celów zawsze będzie wiązała się z wysokimi kosztami, a ostatecznie – z
        porażką całego projektu.
      </p>
      <p>
        Jedynym odpowiednim celem dla projektów informatycznych może być
        <strong>
          {" "}
          rozwiązywanie problemów biznesu lub dostarczanie możliwości
        </strong>
        , które wcześniej były nieosiągalne. Zatem jeśli chcesz, aby Twój
        projekt wspierał dynamiczny wzrost Twojej firmy, koncentruj się na
        problemach, które chcesz rozwiązać. Nigdy nie stosuj niedookreślonych
        lub źle określonych kryteriów – one na pewno wygenerują zbędne koszty, a
        nie dadzą Ci rozwiązania problemu.
      </p>
      <br />
      <br />
      <h2>
        <strong> Ustal priorytety </strong>{" "}
      </h2>
      <p>
        Wyobraź sobie, że w czasie wojny dowództwo wysyła pilota na misję w celu
        zniszczenia trzech obiektów wroga. Zazwyczaj jest tak, że pojedynczy
        pocisk nie niszczy celu za pierwszym razem. Dlatego właśnie pilot
        powinien mieć wyznaczone priorytety celów. To daje pewność, że nie
        pojawi się sytuacja, w której cel trzeciorzędny zostanie zrealizowany, a
        podstawowy nie.
      </p>
      <p>
        Podobnie podczas realizacji kluczowych projektów informatycznych często
        zdarzają się sytuacje, w których opracowywanie produktu implikuje
        problem, stający się źródłem dodatkowego kosztu lub ponadwymiarowego
        czasu. Warto jednak wiedzieć, że{" "}
        <strong>
          {" "}
          równie często pojawiają się nowe szanse, których wykorzystanie jest
          bardzo opłacalne dla firmy
        </strong>{" "}
        (dużo bardziej niż sztywne trzymanie się planu). I tu znów konieczne
        jest priorytetowanie działań.
      </p>
      <p>
        Realizacja projektu według jasno ustalonych priorytetów{" "}
        <strong> zabezpiecza Twoją firmę przed sytuacją</strong>, w której
        powstające rozwiązanie posiada sporo ciekawych “fajerwerków”, a nie
        posiada najistotniejszej funkcji – takiej, która powoduje, że system nie
        nadaje się do użycia. Realizacja zadań o wysokim priorytecie zawsze
        zwiększa szanse na sukces.
      </p>
      <br />
      <br />
      <h2>
        <strong> Stawiaj na elastyczność i otwartość na zmiany </strong>{" "}
      </h2>
      <p>
        Jedno, czego możesz być w 100% pewien to tego, że wszystko się zmienia.
        Zmianie ulegnie Twoje otoczenie i Twoja sytuacja, zmienią się też Twoje
        potrzeby. Pytanie brzmi – kiedy się to stanie? Jeśli Twój projekt
        informatyczny{" "}
        <strong>
          {" "}
          nie polega na wdrożeniu czegoś „z pudełka” (np. instalacji prostego
          programu do fakturowania), to Twoje oczekiwania w trakcie projektu
          mogą się zmienić
        </strong>
        .
      </p>
      <p>
        Po drugie, dostawca w trakcie wdrożenia systemu IT w firmie coraz lepiej
        ją poznaje, dzięki czemu może zaproponować lepsze rozwiązania. Jasno
        stąd wynika, że{" "}
        <strong>
          {" "}
          elastyczność to najlepsza droga do sukcesu we wdrożeniu systemu IT
        </strong>{" "}
        – co zawsze wyraźnie podkreślamy w rozmowach z naszymi klientami i co
        przyczynia się do sukcesu naszych klientów w realizowanych projektach.
      </p>
      <br />
      <br />
      <h2>
        <strong> Dobieraj rozwiązanie do swoich potrzeb </strong>{" "}
      </h2>
      <p>
        Przypomnij sobie, kiedy kupiłeś swój ostatni samochód. Na pewno{" "}
        <strong>
          {" "}
          decyzja o wyborze była pokierowana Twoimi wcześniejszymi
          doświadczeniami, celem
        </strong>
        , dla którego kupiłeś auto oraz Twoim portfelem. Nie wybierzesz
        Mercedesa, jeśli stać Cię tylko na Forda. Nie kupisz półciężarówki,
        jeśli mieszkasz w mieście i auto służy Ci tylko do dojazdu do pracy.
        <strong>
          {" "}
          Chyba, że kompletnie nie potrafisz ocenić swoich potrzeb
        </strong>
        .
      </p>
      <p>
        Podobnie jest w świecie rozwiązań IT. Poszukując dynamicznego wzrostu
        managerowie czasami decydują się na systemy, których nigdy nie
        wykorzystają. System informatyczny jest maszyną, której{" "}
        <strong>
          {" "}
          wdrożenie powinno zawsze mieć konkretny cel o wymiarze biznesowym
        </strong>
        , a inwestycja powinna się zwrócić. W Questy zawsze upewniamy się, że
        klient wygeneruje dla siebie konkretne wartości, np. wzrost sprzedaży
        bądź usprawnienie obsługi klienta.
      </p>
      <br />
      <br />
      <h2>
        <strong> Zaangażuj siebie i Twoich współpracowników </strong>{" "}
      </h2>
      <p>
        Szukając najlepszego scenariusza synergii biznesu z IT, warto pamiętać,
        że{" "}
        <strong>
          {" "}
          kluczem do sukcesu jest wyznaczanie właściwego kierunku oraz
          priorytetowanie celów
        </strong>
        . Cokolwiek nie postanowisz, zaakceptuj istnienie ryzyka (zagrożeń oraz
        szans) oraz ustal z dostawcą, jak chcesz tym ryzykiem zarządzać. Wdrażaj
        rozwiązania dopasowane do Twoich potrzeb i możliwości. A co
        najważniejsze – koniecznie szybko{" "}
        <strong>
          {" "}
          angażuj swój zespół, aby system mógł odpowiedzieć na jego potrzeby
        </strong>{" "}
        i by mógł być wykorzystany efektywnie. Do dzieła!
      </p>
      <p>
        {" "}
        Łukasz Tadyszak
        <br /> Założyciel i CEO Questy
        <br />
        Dostawcy nowoczesnych rozwiązań dla biznesu{" "}
      </p>
      <p>
        {" "}
        Tekst ukazał się w magazynie{" "}
        <strong>
          {" "}
          <a href="http://magazynlbq.pl/">Law Business Quality</a>{" "}
        </strong>{" "}
        w marcu 2016r.{" "}
      </p>
    </Artykul>
  );
};

export default Lawbusinessquality;
